import type { Ref } from 'vue'
import type { DocumentForModal } from '@workflow'
import { useAnalytics } from '#imports'

export const useDeleteImportDocument = (
  divisionId: string,
  selectedDocuments: Ref<DocumentForModal[]>,
  from: 'portfolio' | 'workflow',
  emitEvent: () => void,
) => {
  const mixpanel = useMixpanel()
  const { deleteDocument } = useApiMutateLeaseDocuments()
  const deletePublishedLease = useApiDeleteLeaseMutation(divisionId)
  const importDocumentIds = computed(() =>
    selectedDocuments.value.map((doc) => doc.id),
  )

  return useConfirmHandler(
    () => {
      if (from === 'portfolio') {
        return deletePublishedLease.mutateAsync({
          leaseIds: importDocumentIds.value,
        })
      } else {
        return deleteDocument.mutateAsync({
          importDocumentIds: importDocumentIds.value,
        })
      }
    },
    {
      modal: {
        title: 'Delete documents',
        submitButton: 'Delete',
        cancelButton: 'Cancel',
        confirmInputText: 'DELETE',
        documents: selectedDocuments,
      },
      loadingMessage: 'Deleting documents...',
      successMessage: 'Documents deleted',
      errorMessage: 'Failed to delete documents',
      beforeOpen: () => {
        if (importDocumentIds.value.length === 0) return false
      },
      submitHandlerOptions: {
        beforeSubmit: () => {
          if (importDocumentIds.value.length === 0) return false
        },
        onSuccess: () => {
          emitEvent()

          mixpanel.people?.increment(
            'Total Files Deleted',
            importDocumentIds.value.length,
          )

          for (const id of importDocumentIds.value) {
            const document = selectedDocuments.value.find(
              (doc) => doc.id === id,
            )
            useAnalytics({
              name: 'Document deleted',
              showDivision: true,
              data: {
                name: document?.name,
                ID: document?.id,
                filename: document?.filename,
                from: title(from),
                divisionId,
              },
            })
          }
        },
      },
    },
  )
}
