import { useMutation, useQueryClient } from '@tanstack/vue-query'
import { toast } from 'vue3-toastify'
import type { FetchError } from 'ofetch'

export const useApiMutateLeaseDocuments = () => {
  const { currentDivision } = useDivisions()
  const queryClient = useQueryClient()

  // LeasesService()
  const deleteDocument = useMutation({
    mutationFn: ({ importDocumentIds }: { importDocumentIds: string[] }) =>
      fetchAPI(`/companies/${currentDivision.value?.id}/importDocuments`, {
        method: 'DELETE',
        query: {
          importDocumentIds,
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getMeImportDocuments', currentDivision.value?.id],
      })
    },
  })

  const publishDocument = useMutation({
    mutationFn: ({ leaseIds }: { leaseIds: string[] }) =>
      fetchAPI(
        `/companies/${currentDivision.value?.id}/leaseRegister/leases/publish`,
        {
          method: 'POST',
          query: {
            leaseIds,
          },
        },
      ),
    onError: (error: FetchError) => {
      useAnalytics({
        name: 'Portfolio: Lease Added Error',
        showDivision: true,
        data: {
          message: error?.message || error?.name,
        },
      })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['getMeImportDocuments', currentDivision.value?.id],
      })

      useAnalytics({
        name: 'Portfolio: Lease Added',
        showDivision: true,
      })
      useMixpanel().people?.increment('Total Files Moved To Portfolio')
    },
  })

  const edit = useMutation({
    mutationFn: ({ leaseDocumentId }: { leaseDocumentId: string }) =>
      fetchAPI(
        `/companies/${currentDivision.value?.id}/leaseDocuments/${leaseDocumentId}`,
        {
          method: 'PUT',
        },
      ),
  })
  return {
    deleteDocument,
    publishDocument,
    edit,
  }
}
