import { useMutation, useQueryClient } from '@tanstack/vue-query'

export const useApiDeleteLeaseMutation = (divisionId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ leaseIds }: { leaseIds: string[] }) =>
      fetchAPI<undefined>(`/companies/${divisionId}/leases`, {
        method: 'DELETE',
        query: {
          leaseIds,
        },
      }),
    onSuccess: async () => {
      const summaryPromise = queryClient.invalidateQueries({
        queryKey: ['getPortfolioSummary', divisionId],
      })
      const portfolioPromise = queryClient.invalidateQueries({
        queryKey: ['useApiPortfolio', divisionId],
      })

      await Promise.allSettled([summaryPromise, portfolioPromise])
    },
  })
}
